import { ProFormDateTimePicker, ProFormSelect } from "@ant-design/pro-form";
import { Button, Form } from "antd";
import { useEnumValue } from "common/enum/use-enum-value";
import SearchBox, { SearchField } from "components/search-box";
import { filterTime, getDate } from "components/tool";
import { useEffect, useState } from "react";
import { Storage } from 'utils/storage-util';

export const searchFields: SearchField[] = [{
  label: '订单ID',
  name: 'id.equals',
  dataType: 'number'
}, {
  label: '原始订单ID',
  name: 'originalOrderId.equals',
  dataType: 'number'
}, {
  label: '快递单号',
  name: 'expressNo.equals',
}, {
  label: '订单编号',
  name: 'orderNo.equals',
  often: true,
}, {
  label: '商品名称',
  name: 'productName.contains',
}, {
  label: 'SPU编码',
  name: 'productCode.equals',
  often: true,
}, {
  label: '商品Id',
  name: 'productId.equals',
  dataType: 'number'
}, {
  label: '收货人手机号',
  name: 'mobile.equals',
  dataType: 'number',
  often: true,
}, {
  label: '收货人',
  name: 'consigneeName.equals',
  often: true,
}, {
  label: '购买人id',
  name: 'buyerUserId',
}, {
  label: '买家授权码',
  name: 'buyerCode.equals',
  often: true,
}];

const SearchFor = (props: any) => {
  let { onSearch } = props
  let [key, setkey]: any = useState(0);
  const { convertEnumToOptionType } = useEnumValue();
  const [form] = Form.useForm();
  const pathName: any = window.location.pathname;
  const saleOrder = pathName.split('sale-order').length > 1; //销售订单
  const purchaseOrder = pathName.split('purchase-order').length > 1; //采购订单

  useEffect(() => {
    const tabsData = JSON.parse(Storage.session.get("tabsData") || '[]');
    let searchObj: any = {};
    tabsData.length > 0 && (searchObj = tabsData.filter((e: any) => e.params.url === pathName)?.[0]?.params?.searchParams || {})
    // console.log(searchObj)
    !!purchaseOrder && form.setFieldsValue({
      'type.equals': 'Purchase',
      ...searchObj,
    })
    !!saleOrder && form.setFieldsValue({
      'type.equals': 'Normal',
      ...searchObj,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseOrder]);

  const onFinish = (values: any) => {

    let newValues = { ...values };

    if (newValues['bookTime.lessThanOrEqual']) {
      newValues['bookTime.lessThanOrEqual'] = filterTime(newValues['bookTime.lessThanOrEqual'])
    }
    if (newValues['bookTime.greaterThanOrEqual']) {
      newValues['bookTime.greaterThanOrEqual'] = filterTime(newValues['bookTime.greaterThanOrEqual'])
    };
    newValues = {
      ...newValues,
      ...newValues.search
    }
    if (newValues.search) {
      Object.keys(newValues.search).map((item, index) => {
        if (!newValues.search[item]) {
          delete newValues.search[item];
        };
        return true;
      });
    };

    delete newValues.search
    onSearch(newValues)
  };




  return (
    <div className="searchFor-wrapper">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}>
        <ProFormDateTimePicker
          name="bookTime.greaterThanOrEqual"
          placeholder="订单开始时间"
          initialValue={getDate(-30)}
        />
        <ProFormDateTimePicker
          name="bookTime.lessThanOrEqual"
          placeholder="订单结束时间"

        />
        {/* <SelectSearch required={false} name="sellerId.equals" placeholder="店铺" labelValue="name" defaultParams='' fetchFunc={fetchStoreList} searchParams='name.contains' />
        <SelectSearch required={false} name="brandId.equals" placeholder="品牌" labelValue="brandName" defaultParams='' fetchFunc={fetchBrandList} searchParams='name.contains' /> */}
        <ProFormSelect
          options={!!saleOrder ? (convertEnumToOptionType('purchaseOrderType').concat().filter(e => ['Normal', 'Related'].indexOf(e.value) > -1) || []) : (!!purchaseOrder ? (convertEnumToOptionType('purchaseOrderType').concat().filter(e => e.value === 'Purchase') || []) : (convertEnumToOptionType('purchaseOrderType') || []))}
          name="type.equals"
          placeholder={'订单类型'}
          disabled={purchaseOrder}
        />
        <SearchBox
          key={key}
          width={300}
          fields={searchFields}
        />
        <Form.Item label=" " colon={false}>
          <Button type="primary" htmlType="submit">
            搜索
          </Button>
          <Button
            className="ml10"
            onClick={() => {
              form.resetFields();
              onFinish({})
              setkey(++key)
            }}
          >
            重置
          </Button>
        </Form.Item>

      </Form>
    </div>
  )
}

export default SearchFor