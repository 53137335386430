import { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { Col, Form, Row } from "antd";
import { createEntity, getEntity, reset, updateEntity } from "./fullGift.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { FullGift } from "model/promotion/fullGift.model";
import ProForm, { ProFormDateTimePicker, ProFormDependency, ProFormDigit, ProFormList, ProFormRadio, ProFormSelect, ProFormSwitch, ProFormText, ProFormTextArea } from "@ant-design/pro-form";
import ProductListEditControl from "pages/pim/product/product-list-edit-control";
import { searchProductList } from "pages/pim/product/product.reducer";
import Title from "antd/lib/typography/Title";
import { useEnumValue } from "common/enum/use-enum-value";
import SelectSearch from "components/select-search";
import { fetchLevelList, fetchStoreList } from "pages/dealer/unit/unit.reducer";
export interface FullGiftEditProps extends EditStateAndDispatchProps<FullGift>, StateProps, DispatchProps, RouteComponentProps {
}

const FullGiftEdit: FC<FullGiftEditProps> = props => {
  console.log(props)
  const { entity } = props;
  const [form] = Form.useForm();
  const { convertEnumToOptionType } = useEnumValue();
  const [storeId, setStoreId] = useState();

  useEffect(() => {
    if (entity.id) {
      entity.customerCodeRestriction && form.setFieldsValue({
        customerCodeRestriction: entity.customerCodeRestriction.join(',')
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity])

  const beforeUpdateEntity = (res?: any) => {
    return new Promise<any>((resolve, reject) => {
      if (res) {
        res.items = res.items.concat().map((e: any) => {
          return {
            ...e,
            customerCodeRestriction: typeof (e.customerCodeRestriction) === 'string' ? e.customerCodeRestriction.split(',') : e.customerCodeRestriction,
          }
        });
        resolve(res);
        // console.log(res, '==========');
        // resolve(false);
      }
    })
  };

  return (
    <EditPage<FullGift>
      title="编辑"
      form={form}
      beforeUpdateEntity={beforeUpdateEntity}
      {...props}
    >
      <Row>
        <Col span={7}>
          <ProFormText
            name="name"
            label="活动名称"
            rules={[{ required: true }]}
          />
        </Col><Col span={1} />
        <Col span={7}>
          <ProFormText
            name="code"
            label="活动编码"
            rules={[{ required: true }]}
          />
        </Col><Col span={1} />
        <Col span={7}>
          <ProFormSelect
            options={convertEnumToOptionType('promotionType')}
            name="promotionType"
            initialValue={'FullGift'}
            label="类型"
            rules={[{ required: true }]}
            disabled
          />
        </Col><Col span={1} />
        <Col span='7'>
          <ProFormDateTimePicker
            name="startTime"
            label="开始时间"
            rules={[{ required: true }]}
          />
        </Col><Col span='1' />
        <Col span='7'>
          <ProFormDateTimePicker
            name="endTime"
            label="结束时间"
            rules={[{ required: true }]}
          />
        </Col><Col span='1' />
        <ProFormList
          name={'items'}
          creatorButtonProps={{
            creatorButtonText: '添加明细',
          }}
          initialValue={[{}]}
          max={1}
          actionRender={(field, action, defaultActionDom) => [defaultActionDom[1]]}
        >
          {(meta, itemIndex, action) => <Row>
            <ProFormText
              name="code"
              label="满赠明细活动编码"
              rules={[{ required: true }]}
            />
            <Col span={1} />
            <ProFormText
              name="name"
              label="满赠明细活动名称"
              rules={[{ required: true }]}
            />
            <Col span={1} />
            <Col span='7'>
              <ProFormDigit
                label="活动库存"
                name={['stockNum']}
                width='md'
                rules={[{ required: true }]}
                initialValue={0}
                extra='为零则不限制，大于0则代表活动库存的数量'
              />
            </Col><Col span='1' />
            {/* <ProFormSwitch
              name={['productRestriction', 'storeEnabled']}
              label="是否有商家限制"
              initialValue={false}
              fieldProps={{
                checkedChildren: '是',
                unCheckedChildren: '否',
              }}
            /> */}
            <Col span={1} />
            <ProFormSwitch
              name={'orderRestrictionEnabled'}
              label="是否有订单限制"
              rules={[{ required: true }]}
              disabled
              initialValue={true}
              fieldProps={{
                checkedChildren: '是',
                unCheckedChildren: '否',
              }}
            />
            <Col span={1} />
            {/* <ProFormDependency name={['orderRestrictionEnabled']}>
              {({ orderRestrictionEnabled }) => {
                return orderRestrictionEnabled && <>
                  <Col span={1} />
                  <ProFormSwitch
                    name={['orderRestriction', 'repeatableForItem']}
                    label="是否每满"
                    initialValue={false}
                    fieldProps={{
                      checkedChildren: '是',
                      unCheckedChildren: '否',
                    }}
                  />
                  <Col span={1} />
                </>
              }
              }
            </ProFormDependency> */}
            <Col span='7'>
              <ProFormRadio.Group
                label="优惠方式"
                name={['orderRestriction', 'restrictionType']}
                width='md'
                initialValue={'Full_Enjoy'}
                rules={[{ required: true }]}
                options={[
                  {
                    label: '每满享（上不封顶）',
                    value: 'Each_Full_Enjoy'
                  }, {
                    label: '满享（不可叠加）',
                    value: 'Full_Enjoy'
                  }
                ]}
              />
            </Col><Col span='1' />
            <Col span='7'>
              <ProFormRadio.Group
                label="活动方式"
                name={['orderRestriction', 'activityType']}
                width='md'
                rules={[{ required: true }]}
                initialValue={'ProductCount'}
                options={[
                  {
                    label: '按商品件数',
                    value: 'ProductCount'
                  }, {
                    label: '按商品金额',
                    value: 'OrderAmount'
                  }
                ]}
              />
            </Col><Col span='1' />
            <ProFormDependency name={['orderRestriction', 'activityType']}>
              {({ orderRestriction }) => {
                return orderRestriction.activityType === 'ProductCount' ? <ProFormText
                  name={['orderRestriction', 'orderItemCount']}
                  label='满多少件'
                  rules={[{ required: true }]}
                /> : <ProFormText
                  name={['orderRestriction', 'orderAmountLimit']}
                  label='满多少金额'
                  rules={[{ required: true }]}
                />
              }}
            </ProFormDependency>
            <Col span='1' />
            <Col span='7'>
              <ProFormText
                name={['rule', 'ruleName']}
                hidden
                initialValue={'FreeGift'}
              />
            </Col><Col span='1' />
            

            {/* <Col span='7'>
              <ProFormRadio.Group
                label="领取方式"
                name={'领取方式'}
                width='md'
                initialValue={true}
                rules={[{ required: true }]}
                options={[
                  {
                    label: '随商品发货',
                    value: '随商品发货'
                  }
                ]}
              />
            </Col><Col span='1' /> */}
            <Col span='7'>
              <ProFormRadio.Group
                label="参与人员"
                name={'customerRestrictionType'}
                width='md'
                rules={[{ required: true }]}
                options={[
                  {
                    label: '全部',
                    value: 'All'
                  }, {
                    label: '指定人员',
                    value: 'CustomerCode'
                  }, {
                    label: '指定等级',
                    value: 'CustomerLevel'
                  }
                ]}
              />
            </Col><Col span='1' />
            <ProFormDependency name={['customerRestrictionType']}>
              {({ customerRestrictionType }) => {
                if (customerRestrictionType === 'CustomerLevel') {
                  return <Col span={24}>
                    <Form.Item name="customerLevelRestriction" label="等级">
                      <SelectSearch optionValue='levelNo' required={true} name="customerLevelRestriction" placeholder="等级" labelValue="levelName" defaultParams='' mode="multiple" fetchFunc={fetchLevelList} searchParams='levelName.contains' />
                    </Form.Item>
                  </Col>
                } else if (customerRestrictionType === 'CustomerCode') {
                  return <Col span={24}>
                    <ProFormTextArea
                      extra='批量粘贴授权码到下面，以“,“分隔，然后点击确定例如：M10007,M10008'
                      rules={[{ required: true }]}
                      name={["customerCodeRestriction"]}
                      label='指定人员显示'
                      placeholder='批量粘贴授权码，以逗号分割'
                    />
                  </Col>
                }
              }}
            </ProFormDependency>
            <ProFormSwitch
              name={'productRestrictionEnabled'}
              label="商品范围限制"
              rules={[{ required: true }]}
              disabled
              initialValue={true}
              fieldProps={{
                checkedChildren: '是',
                unCheckedChildren: '否',
              }}
            />
            <Col span={1} />
            <ProFormDependency name={['productRestrictionEnabled']}>
              {({ productRestrictionEnabled }) => {
                return productRestrictionEnabled && <>
                  <ProFormSwitch
                    name={['productRestriction', 'exclude']}
                    label="排除所选商品"
                    initialValue={false}
                    hidden
                    fieldProps={{
                      checkedChildren: '是',
                      unCheckedChildren: '否',
                    }}
                  />
                  <Col span={1} />
                  <ProForm.Item name={'storeId'}>
                    <SelectSearch name="storeId" fetchOptionObj={(e: any) => setStoreId(e.value)} valueType="number" width="md" label='商家' labelValue="name" defaultParams='id.equals' fetchFunc={fetchStoreList} searchParams='name.contains' />
                  </ProForm.Item>
                  <Col span={1} />
                  <Col span={24}>
                    <ProForm.Item label="选择商品" name={['productRestriction', 'ids']} >
                      <ProductListEditControl productSearch={{hiddenSellerId:true,hiddenBrandId:true,}} requestParams={{ 'sellerId.equals': storeId }} fetchFunc={searchProductList} hideProductIds />
                    </ProForm.Item>
                  </Col>
                </>
              }
              }
            </ProFormDependency>
            <ProFormDependency name={['orderRestriction', 'activityType']}>
              {({ orderRestriction }) => {
                return <Col span={24} style={{ width: '1600px', marginBottom: '40px', border: '4px dashed #d9d9d9', padding: '20px', boxSizing: 'border-box' }}>
                  <Col span={24}>
                    <ProFormSwitch
                      name={['rule', 'hasStep']}
                      label="赠品是否阶梯形式"
                      initialValue={false}
                      fieldProps={{
                        checkedChildren: '是',
                        unCheckedChildren: '否',
                      }}
                    />
                  </Col>
                  <ProFormDependency name={['rule', 'hasStep']}>
                    {({ rule }) => {
                      console.log(rule)
                      return rule.hasStep ? <>
                        <ProFormList
                          name={['rule', 'steps']}
                          creatorButtonProps={{
                            creatorButtonText: '添加规则',
                          }}
                          initialValue={[{}]}
                          actionRender={(field, action, defaultActionDom) => [defaultActionDom[1]]}
                        >
                          {(meta, itemIndex, action) => <Row>
                            <Col span={24}><Title level={4}>规则明细-{itemIndex}</Title></Col>
                            <div style={{ width: '1680px', marginBottom: '40px', border: '4px dashed #d9d9d9', padding: '20px', boxSizing: 'border-box' }}>
                              <Col span={8}>
                                {orderRestriction.activityType === 'ProductCount' ? <ProFormText
                                  name={['orderItemCount']}
                                  label='满多少件'
                                  rules={[{ required: true }]}
                                /> : <ProFormText
                                  name={['orderAmount']}
                                  label='满多少金额'
                                  rules={[{ required: true }]}
                                />}
                              </Col>

                              <Col span={24}>
                                <ProFormList
                                  name={['gifts']}
                                  creatorButtonProps={{
                                    creatorButtonText: '添加赠品',
                                  }}
                                  initialValue={[{}]}
                                  actionRender={(field, action, defaultActionDom) => [defaultActionDom[1]]}
                                >
                                  {(meta, itemIndex, action) => <Row style={{ width: '1500px' }}>
                                    <Col span={24}><Title level={5}>赠品明细-{itemIndex}</Title></Col>
                                    <Col span={18}>
                                      <ProForm.Item label="" name={'productId'} rules={[{ required: true }]}>
                                        <ProductListEditControl productSearch={{hiddenSellerId:true,hiddenBrandId:true,}} requestParams={{ 'sellerId.equals': storeId }} type='obj' multiple={false} fetchFunc={searchProductList} hideProductIds />
                                      </ProForm.Item>
                                    </Col>
                                    <Col span={6}>
                                      <ProFormDigit
                                        name="numberOfItem"
                                        label="赠品数量"
                                        rules={[{ required: true }]}
                                      />
                                    </Col>
                                  </Row>
                                  }
                                </ProFormList>
                              </Col>
                            </div>
                          </Row>}
                        </ProFormList>
                      </> : <>
                        <Col span={24}></Col>
                        <Col span={24}>
                          <ProFormList
                            name={['rule', 'gifts']}
                            creatorButtonProps={{
                              creatorButtonText: '添加赠品',
                            }}
                            initialValue={[{}]}
                            max={1}
                            actionRender={(field, action, defaultActionDom) => [defaultActionDom[1]]}
                          >
                            {(meta, itemIndex, action) => <Row>
                              <Col span={24} style={{ width: '1500px' }}></Col>
                              <Col span={18}>
                                <ProForm.Item label="" name={'productId'} rules={[{ required: true }]}>
                                  <ProductListEditControl productSearch={{hiddenSellerId:true,hiddenBrandId:true,}} requestParams={{ 'sellerId.equals': storeId }} type='obj' multiple={false} fetchFunc={searchProductList} hideProductIds />
                                </ProForm.Item>
                              </Col>
                              <Col span={6}>
                                <ProFormDigit
                                  name="numberOfItem"
                                  label="赠品数量"
                                  rules={[{ required: true }]}
                                />
                              </Col>
                            </Row>
                            }
                          </ProFormList>
                        </Col>
                      </>
                    }}</ProFormDependency>
                </Col>
              }
              }
            </ProFormDependency>
          </Row>}
        </ProFormList>
      </Row>
    </EditPage>);
};

const mapStateToProps = ({ fullGift }: RootState) => ({
  entity: fullGift.entity,
  updateSuccess: fullGift.updateSuccess,
  updating: fullGift.updating,
  createSuccess: fullGift.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FullGiftEdit);
