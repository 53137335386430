import { FC, ReactNode, useState } from "react";
import { Button, Form, Input, Popconfirm, message } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import RefundDetails from "./refund-details";
import { deleteEntity, getEntities, getEntity, manualRefund, refundOrder } from "./refund.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import RefundEdit from "./refund-edit";
import { Refund } from "model/procure/refund.model";
import { ModalForm, ProFormSelect, ProFormText } from "@ant-design/pro-form";
import { useEnumValue } from "common/enum/use-enum-value";
import { ForceUpdate } from "model/system/version.model";

export interface RefundListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const columns: ProColumns<Refund, 'pruchaseRefundStatus' | 'purchaseRefundSource'>[] = [{
  title: '订单id',
  dataIndex: 'orderId',
  width: 100,
  ellipsis: true,
}, {
  title: '订单号',
  dataIndex: 'orderNo',
  width: 100,
  ellipsis: true,
}, {
  title: '用户授权号',
  dataIndex: 'customerCode',
  width: 100,
}, {
  title: '支付方式',
  dataIndex: 'payTypeName',
  width: 100,
}, {
  title: '退款流水单号',
  dataIndex: 'refundNo',
  width: 120,
}, {
  title: '支付总金额',
  dataIndex: 'payAmount',
  width: 100,
}, {
  title: '支付货款',
  dataIndex: 'payGoodsAmount',
  width: 100,
}, {
  title: '支付赠送货款',
  dataIndex: 'payGiftAmount',
  width: 120,
}, {
  title: '应退款金额',
  dataIndex: 'refundAmount',
  width: 120,
}, {
  title: '实际退款金额',
  dataIndex: 'actualRefundAmount',
  width: 120,
}, {
  title: '退款状态',
  dataIndex: 'status',
  valueType: 'pruchaseRefundStatus',
  width: 100,
}, {
  title: '申请时间',
  dataIndex: 'createdDate',
  width: 100,
}, {
  title: '退款时间',
  dataIndex: 'refundTime',
  width: 100,
}, {
  title: '是否整单退款',
  dataIndex: 'isWholeOrder',
  valueEnum: ForceUpdate,
  width: 120,
}, {
  title: '申请来源',
  dataIndex: 'source',
  valueType: 'purchaseRefundSource',
  width: 100,
}, {
  title: '备注',
  dataIndex: 'remark',
  width: 100,
},];


const RefundList: FC<RefundListProps> = (props) => {
  const { convertEnumToOptionType } = useEnumValue();
  const [refreshFlag, setRefreshFlag] = useState(0);
  const searchFields = [{
    label: '订单id',
    name: 'orderId.equals',
  }, {
    label: '订单号',
    name: 'orderNo.equals',
  }, {
    label: '授权码',
    name: 'customerCode.equals',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormSelect
          options={convertEnumToOptionType('pruchaseRefundStatus')}
          name="status.equals"
          placeholder="退款状态"
        />
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );

  const rowActions = [(dom: any, record: any) => {
    return <Popconfirm title={"你确定要退款此订单吗？"} key="activate" onConfirm={() => {
      refundOrder({ id: record.id }).then((e) => {
        message.success('操作成功');
        setRefreshFlag(new Date().getTime());
      }).catch(e => {
        message.error(e.response.data.message);
      });
    }}
      disabled={(record.status !== 'CREATED')}
    >
      <Button disabled={(record.status !== 'CREATED')} type="link" size="small" >退款</Button>
    </Popconfirm>
  }, (dom: any, record: any) => {
    return <ModalForm
      title={'手动处理退款'}
      trigger={<Button type="link" size="small">手动处理退款</Button>}
      onFinish={async (data: any) => {
        console.log(data)
        return new Promise<boolean>((resolve, reject) => {
          manualRefund({
            ...data,
            id:record.id,
          }).then((e) => {
            message.success(`操作成功`);
            resolve(true);
          }).catch((e) => {
            message.error(`操作失败`);
            resolve(false);
          })
        })
        
        // return true;
      }}
    >
      <ProFormText name='remark' label='备注' />
      <ProFormText name='refundNo' label='退款流水号' />
    </ModalForm>
  }]

  return (
    <ListViewPage<Refund, 'pruchaseRefundStatus' | 'purchaseRefundSource'>
      columns={columns}
      hideDeleteButton
      hideCreateButton
      hideEditButton
      refreshFlag={refreshFlag}
      rowActions={rowActions}
      quickFilterPanel={quickFilterPanel}
      detailPage={<RefundDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={0}
      editPage={(params: any) => <RefundEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ refund }: RootState) => ({
  entity: refund.entity,
  entities: refund.entities,
  loading: refund.loading,
  updateSuccess: refund.updateSuccess,
  createSuccess: refund.createSuccess,
  total: refund.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RefundList);