import React from 'react';
import {RouteComponentProps, Router} from '@reach/router';
import OrderCancel from './order-cancel';
import OrderAfterSales from './order-after-sales';
import PurchaseOrder from './purchase-order';
import AfterSales from './after-sales';
import WholesaleOrder from './wholesale-orders';
import Refund from './refund';

const Procure: React.FC<RouteComponentProps> = () => (
  <Router>
    <PurchaseOrder path="purchase-order/*" />
    <PurchaseOrder path="sale-order/*" />
    <WholesaleOrder path="wholesale-order/*" />
    <OrderCancel path="order-cancel/*" />
    <OrderAfterSales path="order-after-sales/*" />
    <AfterSales path="afterSales/*" />
    <Refund path="refund/*" />
  </Router>
);
export default Procure;
