import { FC, ReactNode } from "react";
import { Form, Input } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import ActivityDetails from "./activity-details";
import { deleteEntity, getEntities, getEntity } from "./activity.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ActivityEdit from "./activity-edit";
import { Activity } from "model/promotion/activity.model";
import { ProFormDateTimePicker, ProFormSelect } from "@ant-design/pro-form";
import { useEnumValue } from "common/enum/use-enum-value";

export interface ActivityListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
const columns: ProColumns<Activity, 'promotionStatus'>[] = [{
  title: 'id',
  dataIndex: 'id',
}, {
  title: '活动名称',
  dataIndex: 'name',
}, {
  title: '活动编码',
  dataIndex: 'code',
}, {
  title: '促销类型',
  dataIndex: 'promotionTypeDesc',
}, {
  title: '创建时间',
  dataIndex: 'createdDate',
}, {
  title: '开始时间',
  dataIndex: 'startTime',
}, {
  title: '结束时间',
  dataIndex: 'endTime',
}, {
  title: '审核状态',
  dataIndex: 'statusDesc',
}, {
  title: '活动状态',
  dataIndex: 'status',
  valueType: 'promotionStatus',
}];


const ActivityList: FC<ActivityListProps> = (props) => {
  const { convertEnumToOptionType } = useEnumValue();
  const searchFields = [{
    label: '活动编码',
    name: 'code.equals',
  }, {
    label: '活动名称',
    name: 'name.equals',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
        <ProFormSelect
          width={'md'} 
          name={'promotionType.equals'}
          options={convertEnumToOptionType('promotionType')}
          placeholder="促销活动类型"
        />
        <ProFormSelect
          width={'md'} 
          name={'status.equals'}
          options={convertEnumToOptionType('promotionStatus')}
          placeholder="状态"
        />
        <ProFormDateTimePicker
          name="startTime.greaterThanOrEqual"
          placeholder="活动开始时间(起)"
        />
        <ProFormDateTimePicker
          name="startTime.lessThanOrEqual"
          placeholder="活动开始时间(止)"
        />
         <ProFormDateTimePicker
          name="endTime.greaterThanOrEqual"
          placeholder="活动结束时间(起)"
        />
        <ProFormDateTimePicker
          name="endTime.lessThanOrEqual"
          placeholder="活动结束时间(止)"
        />
        <div className="wd10" />
      </Input.Group>
    </Form.Item>
  );
  return (
    <ListViewPage<Activity, 'promotionStatus'>
      columns={columns}
      hideDeleteButton
      quickFilterPanel={quickFilterPanel}
      detailPage={<ActivityDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      editPage={(params: any) => <ActivityEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ activity }: RootState) => ({
  entity: activity.entity,
  entities: activity.entities,
  loading: activity.loading,
  updateSuccess: activity.updateSuccess,
  createSuccess: activity.createSuccess,
  total: activity.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ActivityList);