import ProTable, { ProColumns } from '@ant-design/pro-table';
import { ColumnsProductStatus, Product } from '../../../model/product/product.model';
import ProductSelectionForm from './product-selection-form';
import { FC } from 'react';
import { fetchProductByIds } from './product.reducer';
import { Button, Form, message, Typography,Image, Space, Tag } from 'antd';
import { ModalForm, ProFormText, ProFormTextArea } from '@ant-design/pro-form';
import { internationalList } from 'components/IntelFormText';
import SelectSearch from 'components/select-search';
import { fetchStoreList } from 'pages/dealer/unit/unit.reducer';
import { fetchSearchBrandList } from '../brand/brand.reducer';

export interface ProductListEditControlProps {
  value?: string[];
  onChange?: (products?: any) => void;
  requestParams?: any;
  multiple?: boolean;
  fetchFunc?: (params: any) => any;
  hideProductIds?: boolean;
  type?: 'obj' | 'arr';
  productSearch?: {
    hiddenSellerId?: boolean;
    hiddenBrandId?: boolean;
  }
}

const ProductListEditControl: FC<ProductListEditControlProps> = props => {
  const { value, onChange, requestParams = {}, multiple = true, fetchFunc, hideProductIds = false, type,productSearch, ...tableProps } = props;
  const [form] = Form.useForm<{ productIds?: string }>();

  const productColumns: ProColumns<Product>[] = [{
    title: '图片',
    hideInSearch: true,
    render: (dom, record) => record.mainPicture && <Image width={80} src={internationalList(record.mainPicture || '')} />
  }, {
    title: '商品信息',
    dataIndex: 'status',
    hideInSearch: true,
    valueEnum: ColumnsProductStatus,
    render: (dom, record) =>
      <Space direction="vertical">
        <Space>
          商品id：<Typography.Text copyable={true}>{record.id}</Typography.Text>
          <Tag color={record.status === 'SELLING' ? 'green' : 'red'}>{dom}</Tag>
        </Space>
        <Typography.Text>{internationalList(record.name || '')}</Typography.Text>
        <Space>
          SPU编码：<Typography.Text copyable={true}>{record.code}</Typography.Text>
        </Space>
      </Space>
  }, {
    title: '商家',
    width: 100,
    dataIndex: 'sellerName',
    hideInSearch: true,
  }, {
    title: '商品名称',
    dataIndex: 'name.contains',
    hideInTable: true,
    renderFormItem: () => {
      return <Form.Item name={'name.contains'}><ProFormText
        width="md"
        name="name.contains"
        label=""
      /></Form.Item>
    }
  }, {
    title: '商品id',
    dataIndex: 'id.equals',
    hideInTable: true,
    renderFormItem: () => {
      return <Form.Item name={'id.equals'}><ProFormText
        width="md"
        name="id.equals"
        label=""
      /></Form.Item>
    }
  }, {
    title: 'SPU编码',
    dataIndex: 'code.equals',
    hideInTable: true,
    renderFormItem: () => {
      return <Form.Item name={'code.equals'}><ProFormText
        width="md"
        name="code.equals"
        label=""
      /></Form.Item>
    }
  }, {
    title: '商家',
    dataIndex: 'sellerId.equals',
    hideInTable: true,
    hideInSearch: !!requestParams?.['sellerId.equals'] || productSearch?.hiddenSellerId,
    width: 100,
    renderFormItem: (dom, record) => {
      return <div className='TableSearchSelect'>
        <Form.Item name={'sellerId.equals'}>
          <SelectSearch width={'md'} required={false} name="sellerId.equals" placeholder="店铺" labelValue="name" defaultParams='' fetchFunc={fetchStoreList} searchParams='name.contains' />
        </Form.Item>
      </div>
    }
  }, {
    title: '品牌',
    dataIndex: 'brandId.equals',
    hideInTable: true,
    hideInSearch: productSearch?.hiddenBrandId ||false,
    width: 100,
    renderFormItem: (dom, record) => {
      return <div className='TableSearchSelect'>
        <Form.Item name={'brandId.equals'}>
          <SelectSearch width='md' required={false} name="brandId.equals" placeholder="品牌" optionValue='id' labelValue="brandName" defaultParams='id.equals' valueType="number" fetchFunc={fetchSearchBrandList} searchParams='name.contains' />
        </Form.Item>
      </div>
    }
  }, {
    title: '商品类目',
    dataIndex: ['category', 'name'],
    width: 100,
    hideInSearch: true,
    render: (text, record) => internationalList(record?.category?.name || '')
  }, {
    title: '品牌',
    dataIndex: ['brand', 'brandName'],
    hideInSearch: true,
    width: 100,
    render: (text, record) => internationalList(record?.brand?.brandName || '')
  }];

  return (
    <ProTable<Product, { productIds?: string[] }>
      rowKey="id"
      columns={productColumns}
      options={false}
      search={false}
      params={{ productIds: typeof(value) ==='string' ? [value] : value || [] }}
      rowSelection={{}}
      // headerTitle="限下列商品参与活动"
      tableAlertOptionRender={({ selectedRowKeys, onCleanSelected }) =>
        <Button type="link" size="small"
          onClick={() => {
            const newValue = value?.concat().map(e => e + '');
            onChange?.((newValue || []).filter(item => selectedRowKeys?.indexOf(item + '' || '') < 0));
            onCleanSelected();
          }}>
          删除
        </Button>}
      toolBarRender={() => [
        <ProductSelectionForm
          trigger="添加"
          multiple={multiple}
          productSearch={productSearch}
          requestParams={requestParams}
          fetchFunc={fetchFunc}
          onFinish={(productIds) => {
            return new Promise<any>((resolve, reject) => {
              const newValue = value?.concat().filter(e => typeof (e) !== 'object');
              const newIds: any = (newValue || []).concat(productIds.filter(id => !(newValue || []).includes(id)));
              if (newIds.length > 200) {
                message.error('商品数量最多为200，请重新选择！');
                resolve(false);
              } else {
                (!multiple && type === 'obj') ? onChange?.(newIds[0]) : onChange?.(newIds);
                resolve(true);
              };
            })

          }}
        />,
        !hideProductIds && <ModalForm<{ productIds?: string }>
          form={form}
          title="批量输入商品ids"
          width={800}
          trigger={<Button>快速添加</Button>}
          modalProps={{
            destroyOnClose: true,
          }}
          preserve={false}
          onVisibleChange={visible => visible && form.setFieldsValue({ productIds: (value || []).toString() })}
          onFinish={async (values) => {
            const newIds = (values.productIds || '').trim().split(',').map(item => item.trim()).filter(item => !!item);
            if (newIds.length > 200) {
              message.error('商品数量最多为200，请重新输入！');
              return false;
            } else {
              onChange?.(newIds);
              return true;
            };
          }}
        >
          <Typography.Text>批量粘贴商品Id到下面，以"，"分割，然后点击确定</Typography.Text>
          <Typography.Text italic>例如：1430438289763639297,1455712100170674178</Typography.Text>
          <ProFormTextArea
            name="productIds"
            allowClear={true}
            placeholder="1430438289763639297,1455712100170674178"
            rules={[{ pattern: RegExp('^\\d+(,(\\s)*\\d+)*$'), message: '数据格式不正确' }]}
          />
        </ModalForm>]}
      request={async (
        params: {
          productIds?: string[];
          pageSize?: number;
          current?: number;
        },
      ) => {
        const { productIds = [], pageSize = 20, current = 1 } = params;
        // const allData
        const ids = productIds.slice((current - 1) * pageSize, current * pageSize);
        const products = await fetchProductByIds(ids);
        return {
          data: products,
          // success 请返回 true，
          // 不然 table 会停止解析数据，即使有数据
          success: true,
          // 不传会使用 data 的长度，如果是分页一定要传
          total: productIds.length,
        };
      }}
      {...tableProps}
    />);
}

export default ProductListEditControl;