import { FC, ReactNode, useState } from "react";
import { Button, Form, Input, message } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import FullGiftDetails from "./fullGift-details";
import { deleteEntity, fullGiftEnableDisable, getEntities, getEntity } from "./fullGift.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import FullGiftEdit from "./fullGift-edit";
import { FullGift } from "model/promotion/fullGift.model";
import { useEnumValue } from "common/enum/use-enum-value";
import Popconfirm from "antd/es/popconfirm";

export interface FullGiftListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}



const FullGiftList: FC<FullGiftListProps> = (props) => {

  const { getEnumValueDesc } = useEnumValue();
  const [refreshTag, setRefreshTag] = useState(0);

  const columns: ProColumns<FullGift, 'promotionStatus' |'orderActivityType' | 'orderRestrictionType'>[] = [{
    title: 'id',
    dataIndex: 'id',
  }, {
    title: '活动名称',
    dataIndex: 'name',
  }, {
    title: '活动编码',
    dataIndex: 'code',
  }, {
    title: '活动方式',
    dataIndex: 'items',
    render:(record:any,text) => {
      return <>{getEnumValueDesc('orderActivityType', record?.[0]?.orderRestriction?.activityType || '')}</>
    }
  }, {
    title: '优惠方式',
    dataIndex: 'items',
    render:(record:any,text) => {
      console.log(record)
      return <>{getEnumValueDesc('orderRestrictionType', record?.[0]?.orderRestriction?.restrictionType || '')}</>
    }
  }, {
    title: '创建时间',
    dataIndex: 'createdDate',
  }, {
    title: '开始时间',
    dataIndex: 'startTime',
  }, {
    title: '结束时间',
    dataIndex: 'endTime',
  }, {
    title: '活动状态',
    dataIndex: 'status',
    valueType: 'promotionStatus',
  }];
  

  const searchFields = [{
    label: '活动编码',
    name: 'code.equals',
  }, {
    label: '活动名称',
    name: 'name.equals',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
          <div className="wd10" />
      </Input.Group>
    </Form.Item>
  );

  const rowActions = [(dom: ReactNode, record: any): ReactNode => (
    record.id &&
    <Popconfirm title={"你确定要" + (record.status ==='Active' ? '禁用' : '启用') + "这个标签组吗？"} key="activate" onConfirm={() => {
      fullGiftEnableDisable({id:record.id,enable:record.status ==='Active' ? false : true}).then((e: any) => {
        message.success('操作成功');
        setRefreshTag(new Date().getTime());
      }).catch((error: any) => {
        message.error(error?.response?.data?.detail);
      });
    }}>
      <Button type="link" size="small" >{record.status ==='Active' ? '禁用' : '启用'}</Button>
    </Popconfirm>
  )];

  return (
    <ListViewPage<FullGift,'promotionStatus' | 'orderActivityType' | 'orderRestrictionType'>
      columns={columns}
      hideDeleteButton
      rowActions={rowActions}
      quickFilterPanel={quickFilterPanel}
      detailPage={<FullGiftDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      refreshFlag={refreshTag}
      filters={{'promotionType.equals': 'FullGift'}}
      editPage={(params: any) => <FullGiftEdit id={params?.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ fullGift }: RootState) => ({
  entity: fullGift.entity,
  entities: fullGift.entities,
  loading: fullGift.loading,
  updateSuccess: fullGift.updateSuccess,
  createSuccess: fullGift.createSuccess,
  total: fullGift.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FullGiftList);